import React from "react"
import {
  LoginBackground,
  SectionCenter,
  LoginWrapper,
  ImageWrapper,
  LoginForm,
  DeskEmptyCol,
  FormCol,
  FormTitle,
  FormText,
  InputForm,
  LinkForm,
  Label,
  DividerContainer,
  Divider,
  DividerText,
  GoogleButton,
  FacebookButton,
  LoginButton,
} from "./styles"
import { FlexBetween, FlexCol, FlexRow, FlexAlignCenterRow } from "../../Global"
import GlobalStyles from "../../../globalStyles"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Head from "../../Global/Head"
const LoginComponent = () => {
  const loginImage = useStaticQuery(graphql`
    query loginImageQuery {
      file(name: { eq: "login" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  return (
    <LoginBackground>
      <Head pageTitle="Login" description="description" keywords="keywords" />
      <GlobalStyles />
      <SectionCenter>
        <LoginWrapper>
          <ImageWrapper>
            <GatsbyImage
              image={loginImage.file.childImageSharp.gatsbyImageData}
              alt={"login trener"}
            />
          </ImageWrapper>
          <LoginForm>
            <DeskEmptyCol />
            <FormCol>
              <FormTitle>LOGOWANIE</FormTitle>
              <FormText>
                ZALOGUJ SIĘ ABY STWORZYĆ SWOJE STUDIO FITNES GDZIEKOLWIEK
                ZAPRAGNIESZ
              </FormText>
              <InputForm type="text" name="email" placeholder="E-MAIL" />
              <InputForm type="password" name="password" placeholder="HASŁO" />
              <LoginButton>ZALOGUJ SIĘ</LoginButton>
              <FlexBetween>
                <FlexCol>
                  <FlexRow>
                    <input
                      type="checkbox"
                      id="remember"
                      name="remember"
                      value="remember"
                    />
                    <Label htmlFor="remember">ZAPAMIĘTAJ MNIE</Label>
                  </FlexRow>
                </FlexCol>
                <FlexCol>
                  <LinkForm to={"/login"}>NIE PAMIĘTASZ HASŁA?</LinkForm>
                </FlexCol>
              </FlexBetween>
              <DividerContainer>
                <DividerText>LUB</DividerText>
                <Divider />
              </DividerContainer>
              <GoogleButton>ZALOGUJ SIĘ PRZEZ KONTO GOOGLE</GoogleButton>
              <FacebookButton>ZALOGUJ SIĘ PRZEZ KONTO FACEBOOK</FacebookButton>
              <FlexAlignCenterRow>
                <FormText style={{ marginRight: "5px" }}>
                  NIE MASZ KONTA?
                </FormText>
                <LinkForm to="/login">ZAREJESTRUJ SIĘ</LinkForm>
              </FlexAlignCenterRow>
            </FormCol>
          </LoginForm>
        </LoginWrapper>
      </SectionCenter>
    </LoginBackground>
  )
}

export default LoginComponent
