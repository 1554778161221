import styled from "styled-components"
import { Section, Container, FlexCol } from "../../Global"
import { Link } from "gatsby"

export const LoginBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.color.darkGreen};
`
export const LinkForm = styled(Link)`
  font-size: 18px;
  color: black;
`

export const Button = styled.button`
  width: 100%;
  height: 48px;
  color: white;
  border: none;
  margin: 5px 0px 5px 0;
  padding: 7px;
  font-size: 18px;
  cursor: pointer;
`

export const GoogleButton = styled(Button)`
  background-color: #d73d32;
`

export const FacebookButton = styled(Button)`
  background-color: #3b5998;
`
export const LoginButton = styled(Button)`
  background-color: ${props => props.theme.color.lightGreen};
`

export const DeskEmptyCol = styled(FlexCol)`
  width: 260px;
`
export const FormTitle = styled.span`
  margin-top: 50px;
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: 36px;
`
export const Label = styled.label`
  font-size: 18px;
`

export const FormText = styled.span`
  font-size: 18px;
  padding: 12px 0px 12px 0;
`

export const DividerText = styled(FormText)`
  position: relative;
  margin-top: 28px;
  font-size: 18px;
  background-color: white;
  z-index: 70;
`

export const DividerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100%;
  &:before {
    z-index: 50;
    content: "";
    position: absolute;
    width: 50px;
    height: 10px;
    background-color: white;
    top: 46px;
    left: 45%;
  }
`
export const Divider = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  height: 1px;
  background-color: black;
`

export const InputForm = styled.input`
  border: 1px solid black;
  padding: 13px;
  margin: 10px 0 10px 0;
  font-size: 18px;
`

export const FormCol = styled(FlexCol)``

export const LoginWrapper = styled(Container)`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`

export const SectionCenter = styled(Section)`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`
export const ImageWrapper = styled.div`
  position: absolute;
  left: 100px;
  width: 610px;
`

export const LoginForm = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 400px;
  width: 770px;
  height: 650px;
  background-color: white;
`
